import request from '@/utils/request'

export function sendVerifyCodeAPI(params) {
  return request({
    url: '/api/user/sendVerifyCode/v1',
    method: 'post',
    params
  })
}

export function loginAPI(params) {
  return request({
    url: '/api/user/login/v1',
    method: 'post',
    params
  })
}
