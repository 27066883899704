export function formatOwnSpec(ownSpec) {
  try {
    return Reflect.ownKeys(ownSpec)
      .filter((spec) => spec !== '__ob__')
      .reduce((pre, cur) => {
        return `${pre + cur}:${ownSpec[cur]};`
      }, '')
  } catch (error) {
    return ''
  }
}

/* 判断客户端 */
export function judgeClient() {
  let client = ''
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // 判断iPhone|iPad|iPod|iOS
    client = 'iOS'
  } else if (/(Android)/i.test(navigator.userAgent)) {
    // 判断Android
    client = 'Android'
  } else {
    client = 'PC'
  }
  return client
}
