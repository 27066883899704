export default {
  namespaced: true,
  state: {
    orderId: '',
    orderItem: {}
  },
  mutations: {
    SET_ORDERID(state, payload) {
      state.orderId = payload
    },
    SET_ORDERITEM(state, payload) {
      state.orderItem = payload
    }
  }
}
