<template>
  <div>
    <van-popup v-model="showLogin" @close="handleClose" class="my-popup" round closeable safe-area-inset-bottom position="bottom">
      <div class="title">手机号登录</div>
      <van-form @submit="handleLogin">
        <van-field v-model="form.phone" placeholder="手机号" :rules="[{ required: true, message: '请输入手机号' }]" />
        <van-field v-model="form.verifyCode" center clearable placeholder="短信验证码" :rules="[{ required: true, message: '请输入短信验证码' }]">
          <template #button>
            <van-button @click="handleSendMsg" :loading="vloading" size="small" type="primary" color="#8076f1">
              <span>发送验证码</span>
              <span>({{ count }})</span>
            </van-button>
          </template>
        </van-field>
        <van-field name="checkbox" :rules="[{ required: true, message: '请同意协议' }]">
          <template #input>
            <van-checkbox v-model="form.checkbox" checked-color="#8076f1" class="rules">
              <div>
                我已阅读并同意
                <span class="rule">《中国移动提供认证服务》</span>
                和YUREN
                <span class="rule">《用户协议》</span>、 <span class="rule">《隐私政策》</span>
              </div>
            </van-checkbox>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button :loading="lloading" plain round block color="#8076f1" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { sendVerifyCodeAPI, loginAPI } from '@/api/user'

  export default {
    name: 'LoginPopup',
    data() {
      return {
        vloading: false,
        cloading: false,
        lloading: false,
        count: 30,
        form: {
          phone: '',
          verifyCode: '',
          checkbox: false
        }
      }
    },
    computed: {
      showLogin: {
        get() {
          return this.$store.state.user.showLogin
        },
        set(val) {
          this.$store.commit('user/UPDATE_SHOW_LOGIN', val)
        }
      },
      callback() {
        return this.$store.state.user.callback
      },
      ...mapState({
        platform: (state) => state.platform
      })
    },
    methods: {
      handleSendMsg() {
        if (this.cloading) {
          return
        }
        this.vloading = true
        sendVerifyCodeAPI({
          phone: this.form.phone,
          type: 1,
          purpose: 3
        })
          .then(() => {
            this.vloading = false
            this.cloading = true
            this.timer = setInterval(() => {
              if (this.count > 0) {
                this.count -= 1
              } else {
                this.cloading = false
                this.count = 30
                clearInterval(this.timer)
              }
            }, 1000)
          })
          .catch(() => {
            this.vloading = false
          })
      },
      handleLogin() {
        this.lloading = true
        loginAPI({
          type: 3,
          phone: this.form.phone,
          verifyCode: this.form.verifyCode
        })
          .then((res) => {
            this.$store.commit('user/UPDATE_USERINFO', res.data)
            this.$store.commit('user/UPDATE_SHOW_LOGIN', false)
            this.$bus.$emit('after_login')
            // 如果是手机应用平台，则向外透传用户信息
            if (this.platform === 'app') {
              this.$dsb.call('login', JSON.stringify(res.data))
            }
            this.lloading = false
          })
          .catch(() => {
            this.lloading = false
          })
      },
      handleClose() {
        this.$bus.$off('after_login')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-popup {
    height: 380px;
    color: #333333;
    .title {
      padding: 20px 12px;
      font-size: 22px;
      color: #5a5a5a;
    }
    .rules {
      font-size: 10px;
      align-items: flex-start;
      .rule {
        color: #8076f1;
      }
    }
  }
</style>
