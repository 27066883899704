<template>
  <div>
    <waterfall :data="list" @loadmore="loadmore" :width="itemWidth" :gutterWidth="gutterWidth">
      <div v-for="(item, index) in list" :key="index">
        <slot :item="item"></slot>
      </div>
    </waterfall>
    <div class="no-more">
      <van-divider v-if="noMore">没有更多了</van-divider>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ItemsList',
    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      },
      noMore: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      itemWidth() {
        return 169 * (document.documentElement.clientWidth / 375) // rem布局 计算宽度
      },
      gutterWidth() {
        return 5 * (document.documentElement.clientWidth / 375) // rem布局 计算x轴方向margin(y轴方向的margin自定义在css中即可)
      }
    },
    methods: {
      loadmore() {
        this.$emit('loadmore')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .no-more {
    padding: 20px 0;
  }
</style>
