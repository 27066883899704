<template>
  <div id="app">
    <keep-alive :include="include">
      <router-view />
    </keep-alive>
    <login-popup></login-popup>
  </div>
</template>
<script>
  import LoginPopup from './components/LoginPopup.vue'

  export default {
    name: 'App',
    components: {
      LoginPopup
    },
    data() {
      return {
        include: ['SearchView', 'HomeView']
      }
    },
    created() {
      this.bootstrap()
    },
    methods: {
      bootstrap() {
        // 设置商城所在平台
        const { platform } = this.$route.query
        if (platform) {
          this.$store.commit('SET_PLATFORM', platform)
        }
        // 如果是app平台，完成同步登陆
        if (platform === 'app') {
          // eslint-disable-next-line
          const userInfo = JSON.parse(this.$dsb.call('userInfo'))
          if (userInfo.id !== 'temp2021') {
            this.$store.commit('user/UPDATE_USERINFO', userInfo)
          }
          // 注册方法用于app退出登陆，同步清空用户信息
          this.$dsb.register('logout', function () {
            this.$store.commit('user/CLEAR_USERINFO')
          })
        }

        // 加载聊天系统 https://docs.easemob.com/cs/300visitoraccess/web-widget
        const chat = document.createElement('script')
        chat.src = '//104199.kefu.easemob.com/webim/easemob.js?configId=a6a8a4a6-838d-445a-b86f-6feb504054b0'
        chat.onload = () => {
          window.easemobim.config = {
            hide: true,
            autoConnect: false
          }
          this.$store.commit('SET_CHAT', true)
        }
        document.body.appendChild(chat)
      }
    }
  }
</script>
<style></style>
