import Vue from 'vue'
import waterfall from 'vue-waterfall2'
import dayjs from 'dayjs'
import VueClipboard from 'vue-clipboard2'
import dsBridge from 'dsbridge'
import App from './App.vue'
import router from './router'
import store from './store'
import { formatOwnSpec } from '@/utils/index'
import './vConsole'

import 'normalize.css'
import '@/components/vant.js'

Vue.use(waterfall)
Vue.use(VueClipboard)
Vue.prototype.$dayjs = dayjs
Vue.prototype.$formatOwnSpec = formatOwnSpec
Vue.prototype.$dsb = dsBridge

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
