export default {
  namespaced: true,
  state: {
    showLogin: false,
    userInfo: JSON.parse(localStorage.getItem('USER_INFO')) || {}
  },
  mutations: {
    UPDATE_SHOW_LOGIN(state, payload) {
      state.showLogin = payload
    },
    UPDATE_USERINFO(state, payload) {
      state.userInfo = payload
      localStorage.setItem('USER_INFO', JSON.stringify(payload))
    },
    CLEAR_USERINFO(state) {
      state.userInfo = {}
      localStorage.removeItem('USER_INFO')
    }
  },
  getters: {
    token(state) {
      return state.userInfo.token
    }
  }
}
