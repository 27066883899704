import request from '@/utils/request'

export function getHotSaleItemsAPI() {
  return request({
    url: '/api/items/hot',
    method: 'get'
  })
}

export function searchItemsAPI(data) {
  return request({
    url: `/api/items/search/${data.pageNo}/${data.pageSize}`,
    method: 'post',
    data
  })
}

export function getItemsDetailsAPI(id) {
  return request({
    url: `/api/items/${id}`,
    method: 'get'
  })
}

export function getMoreGoodiesAPI(params) {
  return request({
    url: `/api/items/moreGoodies/${params.pageNo}/${params.pageSize}`,
    method: 'get'
  })
}
