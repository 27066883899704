<template>
  <div class="page-base">
    <van-pull-refresh v-model="pullLoading" @refresh="onRefresh">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="item in carousel" :key="item.id">
          <van-image @click="handleCarouselClick(item)" class="banner" fit="cover" :src="item.imageUrl">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
      <div @click="handleToSearch" class="search">
        <img class="search-icon" src="@/assets/img/search_white_icon.png" alt="" />
        <span>搜索您想查找的商品</span>
      </div>
      <div class="content">
        <van-row class="types">
          <van-col class="type" v-for="item in category" :key="item.id" @click="handleToCategory(item)" span="6">
            <van-image class="type-img" fit="cover" :src="item.logo" />
            <div>{{ item.name }}</div>
          </van-col>
        </van-row>
        <div @click="handleToCoupon" class="coupon">
          <img src="https://yoga-zh-1304465545.cos.ap-shanghai.myqcloud.com/coupon_ad.png" alt="" />
        </div>
        <div class="title">热门推荐</div>
        <div class="recommends">
          <div v-if="hotRecommend[0]" @click="handleToProductDetails(hotRecommend[0])" class="right rec01">
            <div class="name">{{ hotRecommend[0].itemName }}</div>
            <div v-if="hotRecommend[0].type === 1" class="price money-real">{{ hotRecommend[0].price }} ¥</div>
            <div v-if="hotRecommend[0].type === 2" class="price money-real">{{ hotRecommend[0].point }} 积分</div>
            <div v-if="hotRecommend[0].type === 3" class="price money-real">{{ hotRecommend[0].price }} ¥ + {{ hotRecommend[0].point }} 积分</div>
            <van-image class="r-img" fit="cover" :src="hotRecommend[0].recommendUrl" alt="" />
          </div>
          <div class="left">
            <div v-if="hotRecommend[1]" @click="handleToProductDetails(hotRecommend[1])" class="rec02">
              <div class="name">{{ hotRecommend[1].itemName }}</div>
              <div v-if="hotRecommend[1].type === 1" class="price money-real">{{ hotRecommend[1].price }} ¥</div>
              <div v-if="hotRecommend[1].type === 2" class="price money-real">{{ hotRecommend[1].point }} 积分</div>
              <div v-if="hotRecommend[1].type === 3" class="price money-real">{{ hotRecommend[1].price }} ¥ + {{ hotRecommend[0].point }} 积分</div>
              <van-image class="l-img" fit="cover" :src="hotRecommend[1].recommendUrl" alt="" />
            </div>
            <div v-if="hotRecommend[2]" @click="handleToProductDetails(hotRecommend[2])" class="rec03">
              <div class="name">{{ hotRecommend[2].itemName }}</div>
              <div v-if="hotRecommend[2].type === 1" class="price money-real">{{ hotRecommend[2].price }} ¥</div>
              <div v-if="hotRecommend[2].type === 2" class="price money-real">{{ hotRecommend[2].point }} 积分</div>
              <div v-if="hotRecommend[2].type === 3" class="price money-real">{{ hotRecommend[2].price }} ¥ + {{ hotRecommend[0].point }} 积分</div>
              <van-image fit="cover" class="l-img" :src="hotRecommend[2].recommendUrl" alt="" />
            </div>
          </div>
        </div>
        <div class="title">热卖爆款</div>
        <div class="hots">
          <div v-for="item in hotSale" :key="item.id" @click="handleToProductDetails(item)" class="hot">
            <van-image class="hot-img" :src="item.thumbnailUrl" fit="cover" alt="" />
            <div class="info">
              <div class="name">{{ item.itemName }}</div>
              <div v-if="item.type === 1" class="money-real">{{ item.price }} ¥</div>
              <div v-if="item.type === 2" class="money-real">{{ item.point }} 积分</div>
              <div v-if="item.type === 3" class="money-real">{{ item.price }} ¥ + {{ item.point }} 积分</div>
            </div>
          </div>
        </div>
        <div class="title">更多好物</div>
        <div class="container-water-fall">
          <template v-if="!list.length">
            <van-empty image="search" description="暂无商品~" />
          </template>
          <items-list v-else :list="list" @loadmore="loadmore" :noMore="noMore">
            <template v-slot="{ item }">
              <goods-item @click="handleToProductDetails" :item="item"></goods-item>
            </template>
          </items-list>
        </div>
        <van-loading v-if="touchBottomLoading" size="18" style="padding: 16px; text-align: center">加载中...</van-loading>
      </div>
    </van-pull-refresh>
    <sidebar-nav></sidebar-nav>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getCarouselAPI, getShopCategoryAPI } from '@/api/other'
  import { getHotSaleItemsAPI, getMoreGoodiesAPI } from '@/api/items'

  import SidebarNav from '@/components/SidebarNav.vue'
  import ItemsList from '@/views/SearchView/components/ItemsList.vue'
  import GoodsItem from '@/views/SearchView/components/GoodsItem.vue'

  export default {
    name: 'HomeView',
    components: {
      SidebarNav,
      ItemsList,
      GoodsItem
    },
    data() {
      return {
        noMore: false,
        pullLoading: false,
        touchBottomLoading: false,
        form: {
          pageNo: 1,
          pageSize: 10
        },
        list: [],
        carousel: [],
        category: [],
        hotSale: [],
        hotRecommend: []
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token'
      })
    },
    created() {
      this.getCarousel()
      this.getShopCategory()
      this.getHotSaleItems()
      this.getList()
    },
    methods: {
      getList() {
        this.noMore = false
        this.form.pageNo = 1
        getMoreGoodiesAPI(this.form).then((res) => {
          this.list = res.data.records
        })
      },
      getCarousel() {
        getCarouselAPI()
          .then((res) => {
            this.carousel = res.data
          })
          .catch(() => {})
      },
      handleCarouselClick(item) {
        // 1 商品，2 外链
        if (item.type === 1) {
          this.$router.push(`/product/details?itemId=${item.itemId}`)
        } else {
          window.open(item.link)
        }
      },
      getShopCategory() {
        getShopCategoryAPI()
          .then((res) => {
            this.category = res.data
          })
          .catch(() => {})
      },
      handleToCategory(item) {
        this.$router.push(`/category?id=${item.id}&name=${item.name}`)
      },
      getHotSaleItems() {
        getHotSaleItemsAPI()
          .then((res) => {
            this.hotSale = res.data.hotSale
            this.hotRecommend = res.data.recommend
          })
          .catch(() => {})
      },
      onRefresh() {
        this.getCarousel()
        this.getHotSaleItems()
        this.getList()
        setTimeout(() => {
          this.pullLoading = false
        }, 500)
      },
      loadmore() {
        if (this.touchBottomLoading || this.noMore) {
          return
        }
        this.touchBottomLoading = true
        this.form.pageNo += 1
        getMoreGoodiesAPI(this.form)
          .then((res) => {
            this.touchBottomLoading = false
            this.list = [...this.list, ...res.data.records]
            if (this.list.length === res.data.total) {
              this.noMore = true
            }
          })
          .catch(() => {
            this.touchBottomLoading = false
          })
      },
      handleToSearch() {
        this.$router.push('/search')
      },
      handleToCoupon() {
        if (this.token) {
          this.$router.push('/coupon')
        } else {
          this.$store.commit('user/UPDATE_SHOW_LOGIN', true)
          this.$bus.$once('after_login', () => {
            this.$router.push('/coupon')
          })
        }
      },
      handleToProductDetails(item) {
        this.$router.push(`/product/details?itemId=${item.id}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-base {
    background: #fff;
  }
  .banner {
    width: 100%;
    height: 255px;
  }
  .search {
    display: flex;
    align-items: center;
    position: absolute;
    width: 320px;
    left: 50%;
    transform: translateX(-50%);
    top: calc(32px + constant(safe-area-inset-top));
    top: calc(32px + env(safe-area-inset-top));
    height: 35px;
    padding: 0 20px;
    color: #fff;
    font-size: 14px;
    background-color: rgba($color: #000000, $alpha: 0.16);
    border-radius: 18px;
    .search-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .content {
    position: relative;
    top: -20px;
    background: #fff;
    border-radius: 20px 20px 0 0;
    min-height: 100vh;
    .types {
      padding: 16px;
      font-size: 14px;
      .type {
        text-align: center;
        margin-bottom: 8px;
        .type-img {
          width: 47px;
        }
        div {
          margin-top: 7px;
        }
      }
    }
    .coupon {
      text-align: center;
      img {
        width: 345px;
      }
    }
    .title {
      padding: 8px 16px;
      font-size: 16px;
      color: #000000;
    }
    .recommends {
      display: flex;
      padding: 8px 16px;
      font-size: 12px;
      .right {
        margin-right: 16px;

        .r-img {
          width: 150px;
          height: 180px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
      .rec01 {
        position: relative;
        .name {
          width: 100px;
          position: absolute;
          top: 18px;
          left: 20px;
          z-index: 2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .price {
          position: absolute;
          top: 40px;
          left: 20px;
          z-index: 2;
        }
      }
      .left {
        .rec02,
        .rec03 {
          position: relative;
          line-height: 18px;
          .name {
            width: 35%;
            position: absolute;
            top: 20px;
            right: 10px;
            z-index: 2;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .price {
            width: 35%;
            position: absolute;
            top: 40px;
            right: 10px;
            z-index: 2;
          }
        }
        .rec02 {
          margin-bottom: 6px;
        }
        .l-img {
          width: 170px;
          height: 86px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
    .hots {
      padding: 8px 16px;
      display: flex;
      overflow-x: auto;
      .hot-img {
        width: 99px;
        height: 93px;
      }
      .hot {
        width: 99px;
        flex-shrink: 0;
        margin-right: 16px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 11px rgba(128, 128, 128, 0.16);
        overflow: hidden;
        font-size: 12px;
        .info {
          padding: 5px;
          .name {
            margin-bottom: 10px;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .container-water-fall {
      padding: 0 16px;

      .cell-item {
        width: 100%;
        margin-bottom: 5px;
        background: #ffffff;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
        .item-body {
          padding: 12px;
          .item-title {
            padding: 10px 0;
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item-price {
            padding: 10px 0;
          }
          .money-real {
            font-size: 16px;
            margin-right: 10px;
          }
          .money-op {
            font-size: 12px;
            text-decoration: line-through;
          }
        }
      }
    }
  }
</style>
