import request from '@/utils/request'

export function getCarouselAPI() {
  return request({ url: '/api/carousel', method: 'get' })
}

export function getShopCategoryAPI() {
  return request({
    url: '/api/shop/category',
    method: 'get'
  })
}

// 获取上传文件权限
export function getJSWriteCr() {
  return request({
    url: '/api/cos/getJSWriteCr/v1',
    method: 'post'
  })
}
