import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/search',
    component: () => import('@/views/SearchView/index.vue')
  },
  {
    path: '/coupon',
    component: () => import('@/views/CouponView/index.vue')
  },
  {
    path: '/mycoupon',
    component: () => import('@/views/CouponView/MyCouponView.vue')
  },
  {
    path: '/category',
    component: () => import('@/views/CategoryView/index.vue')
  },
  {
    path: '/product/details',
    component: () => import('@/views/ProductDetailsView/index.vue')
  },
  {
    path: '/cart',
    component: () => import('@/views/ShoppingCartView/index.vue')
  },
  {
    path: '/settlement',
    component: () => import('@/views/SettlementView/index.vue')
  },
  {
    path: '/settlement/tips',
    component: () => import('@/views/SettlementView/BuyTipsView.vue')
  },
  {
    path: '/settlement/payment',
    component: () => import('@/views/SettlementView/PaymentView.vue')
  },
  {
    path: '/address',
    component: () => import('@/views/AddressView/index.vue')
  },
  {
    path: '/address/form',
    component: () => import('@/views/AddressView/AddressForm.vue')
  },
  {
    path: '/order',
    component: () => import('@/views/OrderView/index.vue')
  },
  {
    path: '/order/details',
    component: () => import('@/views/OrderView/OrderDetailsView.vue')
  },
  {
    path: '/refund',
    component: () => import('@/views/RefundView/index.vue')
  },
  {
    path: '/refund/details',
    component: () => import('@/views/RefundView/RefundDetailsView.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

export default router
