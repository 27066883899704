<template>
  <div class="side">
    <img @click="handleToShoppingCart" src="@/assets/img/shopping_cart_icon.png" alt="" />
    <img @click="handleToOrderList" src="@/assets/img/list_icon.png" alt="" />
    <img @click="handleToService" src="@/assets/img/service_icon.png" alt="" />
    <img v-if="token && platform !== 'app'" @click="handleLogout" src="@/assets/img/logout_icon.png" alt="" />
    <transition name="fade">
      <img class="tp" v-if="top > 3000" @click="handleScrollTop" src="@/assets/img/top_icon.png" alt="" />
    </transition>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import { Dialog, Toast } from 'vant'

  export default {
    name: 'SidebarNav',
    data() {
      return {
        top: 0
      }
    },
    computed: {
      ...mapState({
        platform: (state) => state.platform,
        chat: (state) => state.chat
      }),
      ...mapGetters({
        token: 'user/token'
      })
    },
    mounted() {
      window.addEventListener('scroll', () => {
        this.top = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
      })
    },
    methods: {
      handleToShoppingCart() {
        if (this.token) {
          this.$router.push('/cart')
        } else {
          this.$store.commit('user/UPDATE_SHOW_LOGIN', true)
          this.$bus.$once('after_login', () => {
            this.$router.push('/cart')
          })
        }
      },
      handleToService() {
        if (!this.token) {
          this.$store.commit('user/UPDATE_SHOW_LOGIN', true)
          return
        }
        if (this.chat && window.easemobim) {
          window.easemobim.bind({
            configId: process.env.VUE_APP_CONFIG_ID
          })
        } else {
          Toast.fail('聊天系统加载中!')
        }
      },
      handleToOrderList() {
        if (this.token) {
          this.$router.push('/order')
        } else {
          this.$store.commit('user/UPDATE_SHOW_LOGIN', true)
          this.$bus.$once('after_login', () => {
            this.$router.push('/order')
          })
        }
      },
      handleScrollTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      handleLogout() {
        Dialog({
          title: '提示',
          message: '确定退出当前账号？',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: '#8076F1',
          cancelButtonColor: '#C7C2FF'
        })
          .then(() => {
            this.$store.commit('user/CLEAR_USERINFO')
            this.$store.commit('user/UPDATE_SHOW_LOGIN', true)
          })
          .catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .side {
    position: fixed;
    right: 16px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    z-index: 3;
    img {
      width: 49px;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba($color: #9d99f0, $alpha: 1);
      margin-bottom: 10px;
    }
    .tp {
      margin-top: 30px;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
