import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store/index'

const BASE_URL = 'http://api.yogayuren.com:9990/yuren' // 测试环境
// const BASE_URL = 'https://v2.yogayuren.com:8886/yuren' // 正式环境
const request = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? BASE_URL : process.env.VUE_APP_BASE_URL,
  timeout: 30000
})

request.interceptors.request.use(
  (config) => {
    const { token, id } = store.state.user.userInfo
    if (token && id) {
      config.headers.token = token
      config.headers.userId = id
    }
    return config
  },
  (error) => {
    Toast.fail('Error')
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    const { code } = response.data
    if (code === 401) {
      store.commit('user/CLEAR_USERINFO')
      store.commit('user/UPDATE_SHOW_LOGIN', true)
    }
    if (code === 200 || code === 1000) {
      return response.data
    }
    Toast.fail(response.data.msg)
    return Promise.reject(response.data.msg)
  },
  (error) => {
    Toast.fail('Error')
    return Promise.reject(error)
  }
)

export default request
