/**
 * 结算页面数据
 * info.items: 商品列表
 * addressId: 选中的收货地址id
 */
export default {
  namespaced: true,
  state: {
    addressId: '',
    placeType: 1, // 下单方式 1：购物车下单 2：直接购买
    items: [],
    payment: JSON.parse(localStorage.getItem('PAYMENT_INFO')) || {} // 支付信息
  },
  mutations: {
    SET_ADDRESSID(state, payload) {
      state.addressId = payload
    },
    SET_PLACETYPE(state, payload) {
      state.placeType = payload
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_PAYMENT(state, payload) {
      state.payment = payload
      localStorage.setItem('PAYMENT_INFO', JSON.stringify(payload))
    }
  },
  actions: {}
}
