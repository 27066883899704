<template>
  <div @click="handleClick" class="cell-item">
    <van-image v-if="item.thumbnailUrl" :src="item.thumbnailUrl" fit="cover" class="item-img" alt="加载错误" />
    <div class="item-body">
      <div class="item-title">{{ item.itemName }}</div>
      <van-tag v-if="item.isDiscount" plain color="#E24E40" text-color="#E24E40">满300减35</van-tag>
      <div class="item-price">
        <span v-if="item.type === 1" class="money-real">¥ {{ item.price }}</span>
        <span v-if="item.type === 2" class="money-real">{{ item.point }} 积分</span>
        <span v-if="item.type === 3" class="money-real">¥ {{ item.price }} + {{ item.point }} 积分</span>
        <span class="money-op">¥ {{ item.originalPrice }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GoodsItem',
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    methods: {
      handleClick() {
        this.$emit('click', this.item)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cell-item {
    width: 100%;
    margin-bottom: 5px;
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    .item-img {
      width: 100%;
      height: auto;
      display: block;
    }
    .item-body {
      padding: 12px;
      .item-title {
        padding: 10px 0;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .item-price {
        padding: 10px 0;
      }
      .money-real {
        font-size: 16px;
        margin-right: 10px;
      }
      .money-op {
        font-size: 12px;
        text-decoration: line-through;
      }
    }
  }
</style>
