import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import settlement from './modules/settlement'
import order from './modules/order'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    platform: localStorage.getItem('PLATFORM') || 'mo', // 移动端网页 mo，官网 ow，手机应用 app
    chat: false // 客服标签是否加载完成
  },
  mutations: {
    SET_PLATFORM(state, payload) {
      state.platform = payload
      localStorage.setItem('PLATFORM', payload)
    },
    SET_CHAT(state, payload) {
      state.chat = payload
    }
  },
  actions: {},
  getters: {},
  modules: {
    user,
    settlement,
    order
  }
})
