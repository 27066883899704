import Vue from 'vue'

import {
  Step,
  Steps,
  List,
  ActionSheet,
  RadioGroup,
  Radio,
  Divider,
  Overlay,
  Image as VanImage,
  Uploader,
  Popup,
  Area,
  Checkbox,
  CheckboxGroup,
  Cell,
  SwipeCell,
  Tab,
  Tabs,
  Button,
  Swipe,
  SwipeItem,
  Icon,
  Col,
  Row,
  PullRefresh,
  Loading,
  Tag,
  Field,
  Form,
  DropdownMenu,
  DropdownItem,
  Empty,
  NavBar
} from 'vant'

Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Icon)
Vue.use(Col)
Vue.use(Row)
Vue.use(PullRefresh)
Vue.use(Loading)
Vue.use(Tag)
Vue.use(Field)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Empty)
Vue.use(NavBar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(SwipeCell)
Vue.use(Cell)
Vue.use(Form)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Area)
Vue.use(Popup)
Vue.use(Uploader)
Vue.use(VanImage)
Vue.use(Overlay)
Vue.use(Divider)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(ActionSheet)
Vue.use(List)
Vue.use(Step)
Vue.use(Steps)
